.swiper-pagination-progressbar-fill{
    background-color: #84bc00 !important;
    /* background-color: green !important; */
}

/* React PDF TextLayer alignment fix */
.react-pdf__Page__textContent span {
	opacity: 0.1;
}
.react-pdf__Page__textContent span::selection {
	background-color: blue;
}
.react-pdf__Document {
	line-height: initial;
}